.it-page-sections-container ul li a {
    display: inline;
}

/* Default desktop img-full style inside contents */
.figure.img-full img {
    min-height: 300px;
    height: auto;
    width: 100%;
    max-height: 600px;
    object-fit: cover;
}

/* Overwrite img-full style for mobile */
@media (max-width: 800px) {
    .figure.img-full img {
        min-height: auto;
        height: auto;
        max-height: none;
        object-fit: initial;
    }
}
